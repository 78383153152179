//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'suffix',
  props: [
    'label',
    'dict'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      },
      isOpen: false
    }
  },
  methods: {
    ...mapMutations('requests', ['update'])
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    suffix: {
      get () { return this.getCurrent.suffix },
      set (val) { this.update({suffix: val}) }
    },
    isSuffixSr  () { return this.suffix === 'Sr.' },
    isSuffixJr  () { return this.suffix === 'Jr.' },
    isSuffixII  () { return this.suffix === 'II' },
    isSuffixIII () { return this.suffix === 'III' },
    isSuffixIV  () { return this.suffix === 'IV' },

    ...mapGetters('requests', ['getCurrent'])
  }
}
